import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

import "../style/index.scss"
import PrenotaOra from "../components/prenotaOra"

const Ansia = ({ data }) => {
  return (
    <Layout page="ansia">
      <Seo
        title="Allarme ansia?! Niente panico!"
        description="Percorso psicoterapeutico breve per il trattamento dell'ansia."
      />
      <article id="ansia">
        <h1>Allarme ansia?! Niente panico!</h1>
        <h2>Percorso psicoterapeutico breve per il trattamento dell'ansia.</h2>
        <div id="feat-pic-wrapper">
          <Img
            className="dontpanic-pic"
            fluid={data.dontPanic.childImageSharp.fluid}
            loading="eager"
          />
        </div>
        <p>
          Il percorso &egrave; volto alla riduzione dell'ansia nel breve tempo e si
          basa su tre moduli di intervento:
        </p>
        <ul>
          <li>Il presente: corpo e mente</li>
          <li>Il passato: come sono arrivato qui</li>
          <li>Il futuro: dove sto andando</li>
        </ul>
        <p>
          Il primo modulo &egrave; mirato alla gestione dell'ansia e alla riduzione dei
          sintomi ansiosi nel presente,
          <em>qui ed ora</em>, con effetti apprezzabili nel breve tempo. Il
          secondo modulo prevede un lavoro pi&ugrave; profondo che va alla ricerca
          della <em>vulnerabilit&agrave; all'ansia</em>, cio&egrave; all'individuazione di
          quegli episodi o situazioni del passato che hanno tutt'ora
          un'influenza importante nella nostra quotidianit&agrave;. Il terzo ed ultimo
          modulo guarda verso il futuro e verso chi vuoi essere da oggi in
          avanti.
        </p>
        <p>Ecco in cosa consistono le tre aree di intervento:</p>
        <ol>
          <li>
            <strong>Il presente</strong>: il fine di questo primo modulo &egrave;
            quello di prendere coscienza del fatto che il nostro corpo ci invia
            dei segnali ben precisi, differenti da persona a persona, i quali ci
            informano su come stiamo e su cosa stia succedendo in noi.
            Attraverso delle sedute guidate imparerai dapprima ad ascoltare i
            segnali del tuo corpo in tutte le loro sfaccettature, quindi a
            riconoscerli ed interpretarli cos&igrave; da poterli vivere pienamente con
            serenit&agrave; e non pi&ugrave; come una minaccia. indagheremo inoltre quali solo
            le trappole della mente in cui tendi a cadere pi&ugrave; spesso, come fanno
            a indurti ogni volta in errore e che legame hanno con la tua ansia.
            Scopriremo insieme come mai queste trappole esistono e cosa fare per
            riconoscerle, trasformandole da problemi a risorse.
          </li>
          <li>
            <strong>Il passato</strong>: tutto ci&ograve; che facciamo oggi lo abbiamo
            imparato ieri! C'&egrave; sempre un motivo se ci si comporta in un certo
            modo, anche il comportamento pi&ugrave; sbagliato un tempo ha avuto un
            senso e ti &egrave; stato utile. Oggi non &egrave; pi&ugrave; cos&igrave; e ti crea solo
            problemi, tuttavia possiamo capire come mai oggi non ti serva pi&ugrave; e
            come lasciarlo andare, imparando a fare qualcosa di diverso che ti
            permetta di vivere meglio.
          </li>
          <li>
            <strong>Il futuro</strong>: ed arrivati a questo punto, non ti resta
            altro da fare se non decidere come vuoi vivere il resto della tua
            vita! Applicando tutto quello che avrai imparato fin qui, potrai
            finalmente decidere quale versione di te stesso essere da oggi in
            avanti, rispettando il tuo passato, accettando il tuo presente e
            scegliendo il tuo futuro.
          </li>
        </ol>
        <p>
          Vuoi intraprendere il tuo percorso di gestione dell'ansia? Compila il
          form e prenota subito il tuo primo colloquio!
        </p>
        <div className="call-to-action">
          <PrenotaOra />
        </div>
      </article>
    </Layout>
  )
}

export default Ansia

export const query = graphql`
  query {
    dontPanic: file(relativePath: { eq: "tonik-U0wwiY6nRGA-unsplash.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
